import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

const container = document.getElementById("root");
const root = createRoot(container);
const meta = {
    title: '',
    meta: [],
    link: [],
    style: [],
    script: [],
  };

  
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <React.Fragment>
                <HelmetProvider>
                    <Helmet {...meta}></Helmet>
                </HelmetProvider>
                <App />
            </React.Fragment>
        </BrowserRouter>
    </React.StrictMode>
);

import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import { instance } from '../components/Utils';
import {notification, Button} from 'antd';
import { SmileOutlined } from '@ant-design/icons';

export default function Consultation(){

    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

    let navigate = useNavigate();
    let [firstName, setFirstName] = useState("");
    let [firstNameError, setFirstNameError] = useState(false);
    let [firstNameErrorMsg, setFirstNameErrorMsg] = useState("");

    let [lastName, setLastName] = useState("");
    let [lastNameError, setLastNameError] = useState(false);
    let [lastNameErrorMsg, setLastNameErrorMsg] = useState("");

    let [email, setEmail] = useState("");
    let [emailError, setEmailError] = useState(false);
    let [emailErrorMsg, setEmailErrorMsg] = useState("");

    let [message, setMessage] = useState("");

    let [dropDown, setDropDown] = useState(false);
    let [selectedOption, setSelectedOption] = useState("none");

    let [sendStatus, setSendStatus] = useState("none");  //none, sending, sent

    const [api, contextHolder] = notification.useNotification();
    
    const openNotification = () => {
      const key = `open${Date.now()}`;
      const btn = (
        
          <Button style={{color: '#108ee9'}} size="small" onClick={() => api.destroy(key)}>
            Ok
          </Button>
      );

      api.success({
        message: 'Message Received',
        description:
          `Hi ${firstName}, we received your message and will get back to you shortly. Meantime you can view our work on Instagram and Youtube @saieagency`,
        btn,
        key,
        onClose: ()=>{navigate('/', {replace : true});},
        duration: 30,
        placement: 'top',
        icon: <SmileOutlined style={{ color: '#108ee9' }} />,
        
      });

    }


    async function submitHandler(){
        let valid_email = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{1,}$/; 
        let letters = /^[a-zA-Z]+$/;

        let badEntry = false;
        if(firstName == ""){
            setFirstNameError(true);
            setFirstNameErrorMsg("First Name is required");
            badEntry = true;
        }
        else if(!letters.test(firstName)){
            setFirstNameError(true);
            setFirstNameErrorMsg("First Name can only contain letters");
            badEntry = true;
        }
        else{
            setFirstNameError(false);
        }

        if(lastName == ""){
            setLastNameError(true);
            setLastNameErrorMsg("Last Name is required");
            badEntry = true;
        }
        else if(!letters.test(lastName)){
            setLastNameError(true);
            setLastNameErrorMsg("Last Name can only contain letters");
            badEntry = true;
        }
        else{
            setLastNameError(false);
        }

        if(email == ""){
            setEmailError(true);
            setEmailErrorMsg("Email Address is required");
            badEntry = true;
        }
        else if(!valid_email.test(email)){
            setEmailError(true);
            setEmailErrorMsg("Email Address is invalid");
            badEntry = true;
        }
        else{
            setEmailError(false);
        }


        if(badEntry == false){
          setSendStatus("sending");
          let res = await instance.post("/saieagency/booking", {'first_name' : firstName, 'last_name' : lastName, 'email' : email, 'message' : message, 'option' : selectedOption});
          await sleep(1000);
          setSendStatus("sent");
          openNotification();           
        }

    }


return(
  
<section class="overflow-hidden">
{contextHolder}
  <div class="container pt-8 px-4 mx-auto">
    <div class="flex flex-wrap -m-8">
      <div class="w-full md:w-1/2 p-8">
        <div class="md:max-w-md">

            <div className='w-auto pb-12'>
                <div className='flex flex-wrap items-center justify-center md:justify-normal'>
                  <div className='w-auto'>
                    <Link to="/"className='relative z-10 inline-block' href='#section-header'>
                      <img src='nightsable-assets/logos/swa_transparent_cropped.png' className='h-[75px] w-[168.78px] object-fit' alt='Saie Web Agency Logo' />
                    </Link>
                  </div>
                </div>
                
            </div>

            <h2 class="mb-8 text-7xl flex justify-center md:justify-normal lg:text-8xl text-white tracking-7xl lg:tracking-8xl">Book Consultation</h2>
            <p class="mb-8 text-gray-300 flex justify-center md:justify-normal">
            We offer full-scale web design, development, and hosting services to 
            help enhance your online presence and pave way for new growth opportunities!
            </p>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-8">
        <div class="relative px-9 py-10 bg-blueGray-900 bg-opacity-30 max-w-lg mx-auto rounded-5xl">
          <h3 class="mb-6 text-2xl font-medium text-white">Contact us</h3>
          
          <div className={`${firstNameError === true ? "border border-red-400" : "border border-gray-900 mb-4"} hover:border-lightTeal focus:ring-4 focus:ring-lightTeal focus:ring-opacity-40 transition duration-300 overflow-hidden rounded-3xl`}>
            <input onChange={(e)=>{setFirstName(e.target.value); setFirstNameError(false);}} value={firstName} class="pl-6 pr-16 py-4 text-lightTeal w-full placeholder-gray-300 outline-none bg-transparent" type="text" placeholder="First Name"/>
          </div>
          <div className={`text-red-400 text-sm mt-2 mb-4 ${firstNameError === true ? "flex" : "hidden"}`}>{firstNameErrorMsg}</div>

          <div className={`${lastNameError === true ? "border border-red-400" : "border border-gray-900 mb-4"} hover:border-lightTeal focus:ring-4 focus:ring-lightTeal focus:ring-opacity-40 transition duration-300 overflow-hidden rounded-3xl`}>
            <input onChange={(e)=>{setLastName(e.target.value); setLastNameError(false);} } value={lastName} class="pl-6 pr-16 py-4 text-lightTeal w-full placeholder-gray-300 outline-none bg-transparent" type="text" placeholder="Last Name"/>
          </div>
          <div className={`text-red-400 text-sm mt-2 mb-4 ${lastNameError === true ? "flex" : "hidden"}`}>{lastNameErrorMsg}</div>

          <div className={`${emailError === true ? "border border-red-400" : "border border-gray-900 mb-4"} hover:border-lightTeal focus:ring-4 focus:ring-lightTeal focus:ring-opacity-40 transition duration-300 overflow-hidden rounded-3xl`}>
            <input onChange={(e)=>{setEmail(e.target.value); setEmailError(false);}} value={email} class="pl-6 pr-16 py-4 text-lightTeal w-full placeholder-gray-300 outline-none bg-transparent" type="text" placeholder="E-mail"/>
          </div>
          <div className={`text-red-400 text-sm mt-2 mb-4 ${emailError === true ? "flex" : "hidden"}`}>{emailErrorMsg}</div>
          

          <div className="relative mb-4" onMouseEnter={()=>{setDropDown(true);}} onMouseLeave={()=>{setDropDown(false)}}>
            <div class="flex justify-between overflow-hidden b-4 border border-gray-900 hover:border-lightTeal focus:ring-4 focus:ring-lightTeal focus:ring-opacity-40 transition duration-300 rounded-3xl">
              <button className={`flex pl-6 pr-16 py-4 ${selectedOption == "none" ? "text-gray-300" : "text-lightTeal"} placeholder-gray-300 outline-none bg-transparent`}>
              {selectedOption == "none" ? "What can we help you with?" : selectedOption}
              </button>
              <div className='flex px-8 items-center '>
                <svg className={`flex content-center ${dropDown === true ? "" : "rotate-180"}`} width="20.16px" height="10px" viewBox="0 0 1185 588" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M592.284 587.499C544.45 587.499 496.616 569.049 460.399 532.832L14.8627 87.2967C-4.95422 67.4799 -4.95422 34.6795 14.8627 14.8627C34.6795 -4.95422 67.4799 -4.95422 87.2968 14.8627L532.833 460.398C565.633 493.198 618.934 493.198 651.734 460.398L1097.27 14.8627C1117.09 -4.95422 1149.89 -4.95422 1169.71 14.8627C1189.52 34.6795 1189.52 67.4799 1169.71 87.2967L724.168 532.832C687.951 569.049 640.117 587.499 592.284 587.499Z" fill="white"/>
                </svg>
              </div>
            </div>
            
            {dropDown === true ?
            <div className={`transition duration-300 transition ease-in-out delay-250 ${dropDown == true ? 'scale-y-full translate-y-0' : 'scale-y-0 -translate-y-1/4'} absolute custom-shadow w-full bg-darkTeal`}>
                
                <label onClick={()=>{setSelectedOption("Website Creation");}} htmlFor='website creation' className="flex items-center justify-between px-3 mt-2 cursor-pointer z-[102]">
                    <div className="flex items-center gap-1.5 font-[PlusJakartaSans-SemiBold] text-[15px] text-lightTeal">Website Creation</div>
                    {selectedOption == "Website Creation" ?
                        <svg width="20" height="20" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.24256 4.76106C1.17099 4.76013 0.86214 4.29825 0.556219 3.73464L0 2.70987L0.144176 2.56571C0.223471 2.48641 0.4366 2.42153 0.617796 2.42153H0.947244L1.11286 2.94333L1.27847 3.46512L2.59644 2.02306C3.32132 1.22992 4.15867 0.450268 4.45721 0.290497L5 0L3.90454 1.2445C3.30204 1.92898 2.48589 3.00061 2.09088 3.62588C1.69587 4.25116 1.31412 4.76198 1.24256 4.76106Z" fill="#56e3ed"/>
                        </svg>
                        :
                        <>
                        </>
                      }
                </label>

                <div onClick={()=>{setSelectedOption("Web Hosting");}} htmlFor='web host' className="flex items-center justify-between px-3 mt-2 cursor-pointer">
                    <div className="flex items-center gap-1.5 font-[PlusJakartaSans-SemiBold] text-[15px] text-lightTeal">Web Hosting</div>
                      {selectedOption == "Web Hosting" ?
                        <svg width="20" height="20" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.24256 4.76106C1.17099 4.76013 0.86214 4.29825 0.556219 3.73464L0 2.70987L0.144176 2.56571C0.223471 2.48641 0.4366 2.42153 0.617796 2.42153H0.947244L1.11286 2.94333L1.27847 3.46512L2.59644 2.02306C3.32132 1.22992 4.15867 0.450268 4.45721 0.290497L5 0L3.90454 1.2445C3.30204 1.92898 2.48589 3.00061 2.09088 3.62588C1.69587 4.25116 1.31412 4.76198 1.24256 4.76106Z" fill="#56e3ed"/>
                        </svg>
                        :
                        <>
                        </>
                      }
                </div>

                <label onClick={()=>{setSelectedOption("Web Maintenace");}} htmlFor='web maintenace' className="flex items-center justify-between px-3 mt-2 cursor-pointer">
                    <div className="flex items-center gap-1.5 font-[PlusJakartaSans-SemiBold] text-[15px] text-lightTeal">Web Maintenace</div>
                    {selectedOption == "Web Maintenace" ?
                        <svg width="20" height="20" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.24256 4.76106C1.17099 4.76013 0.86214 4.29825 0.556219 3.73464L0 2.70987L0.144176 2.56571C0.223471 2.48641 0.4366 2.42153 0.617796 2.42153H0.947244L1.11286 2.94333L1.27847 3.46512L2.59644 2.02306C3.32132 1.22992 4.15867 0.450268 4.45721 0.290497L5 0L3.90454 1.2445C3.30204 1.92898 2.48589 3.00061 2.09088 3.62588C1.69587 4.25116 1.31412 4.76198 1.24256 4.76106Z" fill="#56e3ed"/>
                        </svg>
                        :
                        <>
                        </>
                      }
                </label>

                <label onClick={()=>{setSelectedOption("Undecided");}} htmlFor='undecided' className="flex items-center justify-between px-3 mt-2 cursor-pointer">
                    <div className="flex items-center gap-1.5 font-[PlusJakartaSans-SemiBold] text-[15px] text-lightTeal">I don't know where to start</div>
                    {selectedOption == "Undecided" ?
                        <svg width="20" height="20" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.24256 4.76106C1.17099 4.76013 0.86214 4.29825 0.556219 3.73464L0 2.70987L0.144176 2.56571C0.223471 2.48641 0.4366 2.42153 0.617796 2.42153H0.947244L1.11286 2.94333L1.27847 3.46512L2.59644 2.02306C3.32132 1.22992 4.15867 0.450268 4.45721 0.290497L5 0L3.90454 1.2445C3.30204 1.92898 2.48589 3.00061 2.09088 3.62588C1.69587 4.25116 1.31412 4.76198 1.24256 4.76106Z" fill="#56e3ed"/>
                        </svg>
                        :
                        <>
                        </>
                      }
                </label>
            </div>
          :
          <>
          </>
        }
        </div>

          <textarea onChange={(e)=>{setMessage(e.target.value);}} value={message} class="mb-3 w-full px-6 py-4 text-lightTeal placeholder-gray-300 bg-transparent border border-gray-900 focus:border-lightTeal hover:border-lightTeal focus:ring-4 focus:ring-lightTeal focus:ring-opacity-40 transition duration-300 overflow-hidden rounded-3xl resize-none" placeholder="Message" rows="9"></textarea>
          <div class="flex flex-wrap justify-end">
            <div class="w-auto">
              <button disabled={sendStatus === "sent"} onClick={()=>{submitHandler(); }} className="flex justify-between px-14 py-4 text-center font-medium tracking-2xl bg-lightTeal text-darkTeal hover:bg-darkTeal hover:text-lightTeal focus:ring-4 focus:ring-lightTeal focus:ring-opacity-40 rounded-full transition duration-300" >

              {sendStatus === "sending" ?
                <svg class="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              :
              sendStatus === "sent" ?
              <svg className="mr-3" width="20" height="20" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.24256 4.76106C1.17099 4.76013 0.86214 4.29825 0.556219 3.73464L0 2.70987L0.144176 2.56571C0.223471 2.48641 0.4366 2.42153 0.617796 2.42153H0.947244L1.11286 2.94333L1.27847 3.46512L2.59644 2.02306C3.32132 1.22992 4.15867 0.450268 4.45721 0.290497L5 0L3.90454 1.2445C3.30204 1.92898 2.48589 3.00061 2.09088 3.62588C1.69587 4.25116 1.31412 4.76198 1.24256 4.76106Z" fill="white"/>
              </svg>
              :
              <>
              </>
              }
                <div>
                  { sendStatus === "sending" ? "Sending" : sendStatus === "sent" ? "Sent" : "Send"}
                </div>
              </button>

            </div>  
          </div>
        </div>
      </div>
    </div>
  </div>
    </section>
);

}

import React from 'react';
import { Link} from "react-router-dom";

export default function Navbar({navbarExpand, setNavbarExpand})  {
    
    return(
        <>
          <div className="container px-4 mx-auto">
            <div className='flex items-center justify-between pt-6 -m-2'>
              <div className='w-auto p-2'>
                <div className='flex flex-wrap items-center'>
                  <div className='w-auto'>
                    <a className='relative z-10 inline-block' href='#section-header'>
                      <img src='nightsable-assets/logos/swa_transparent_cropped.png' className='h-[75px] w-[168.78px] object-fit' alt='Saie Web Agency Logo' />
                    </a>
                  </div>
                </div>
              </div>
              <div className='w-auto p-2'>
                <div className='flex flex-wrap items-center'>
                  <div className='w-auto hidden lg:block'>
                    <ul className='flex items-center mr-12'>
                      
                      <li className='mr-12 font-medium tracking-tighter text-white hover:text-opacity-90'>
                        <a href='#section-services'>Services</a>
                      </li>

                      <li className='mr-12 font-medium tracking-tighter text-white hover:text-opacity-90'>
                        <a href='#section-ourwork'>Our Work</a>
                      </li>
                      
                      <li className='font-medium tracking-tighter text-white hover:text-opacity-90'>
                        <a href='#section-aboutus'>About us</a>
                      </li>
                      
                    </ul>
                  </div>
                  
                  <div className='w-auto hidden lg:block'>
                    <div className='inline-block'>
                      <Link to="/consultation" className="inline-block px-8 py-4 transition duration-300 border-2 rounded-full border-lightTeal bg-darkTeal text-lightTeal hover:text-black hover:bg-lightTeal focus:border-darkTeal focus:border-opacity-40 hover:border-darkTeal focus:ring-4 focus:ring-darkTeal focus:ring-opacity-40">
                        Get in touch
                      </Link>
                    </div>
                  </div>

                  <div className='w-auto lg:hidden'>
                    <button onClick={()=>{setNavbarExpand(true);}} className='relative z-10 inline-block'>
                      <svg
                        className='navbar-burger text-lightTeal '
                        width={51}
                        height={51}
                        viewBox='0 0 56 56'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          width={56}
                          height={56}
                          rx={28}
                          fill='currentColor'
                        />
                        <path
                          d='M37 32H19M37 24H19'
                          stroke='black'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${navbarExpand === false ? '-translate-x-full' : 'translate-x-0 '} w-full max-w-[60%] ease-in-out duration-300 bg-darkTeal navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-[101]`}>
            <nav className='relative z-10 px-9 pt-8 h-full overflow-y-auto'>
              <div className='flex flex-wrap justify-between h-full'>
                <div className='w-full'>
                  <div className='flex items-center justify-between -m-2'>
                    
                    <div className='w-auto p-2'>
                      <a href="/#section-header" className='inline-block'>
                        <img src='nightsable-assets/logos/swa_transparent_cropped.png' alt='' />
                      </a>
                    </div>
                    
                    <div className='h-full self-start w-auto p-2'>
                      <button onClick={()=>{setNavbarExpand(false);}} className='navbar-burger inline-block text-white'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M6 18L18 6M6 6L18 18'
                            stroke='currentColor'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col justify-center w-full py-16'>
                  <ul>
                    
                    <li className='mb-8 font-medium tracking-tighter text-lightTeal hover:text-opacity-90'>
                      <a href='#section-services'>Services</a>
                    </li>
                    {/* 
                        #section-aboutus
                        #section-services
                        #section-ourwork
                    */}
                    <li className='mb-8 font-medium tracking-tighter text-lightTeal hover:text-opacity-90'>
                      <a href='#section-ourwork'>Our Work</a>
                    </li>

                    <li className='font-medium tracking-tighter text-lightTeal hover:text-opacity-90'>
                      <a href='#section-aboutus'>About us</a>
                    </li>
                    
                  </ul>
                </div>
                <div className='flex flex-col justify-end w-full pb-8'>
                  <Link to="/consultation" className='flex justify-center inline-block px-8 py-4 transition duration-300 border-2 rounded-full border-lightTeal bg-darkTeal text-lightTeal hover:text-black hover:bg-lightTeal focus:border-darkTeal focus:border-opacity-40 hover:border-darkTeal focus:ring-4 focus:ring-darkTeal focus:ring-opacity-40'>
                    Get in touch
                  </Link>
                </div>
              </div>
            </nav>
            </div>
            <div onClick={()=>{setNavbarExpand(false);} } className={`${navbarExpand === false ? "hidden" : ""} w-screen navbar-backdrop fixed inset-0 bg-gray-800 opacity-80 z-[100]` }/>
          </>
        );

                }
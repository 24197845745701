import React, {useEffect} from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';
import LandingPage from './pages/LandingPage.js';
import Consultation from './pages/Consultation.js';
import axios from 'axios';



function App() {
  
  async function getLocation(){
    let res = await axios.get('https://extreme-ip-lookup.com/json/?key=CvNPLL2kXSIwAjZxYEJl');
    window.localStorage.setItem("country", JSON.stringify(String(res.data['country'])));
  }

  useEffect(()=>{

    if(JSON.parse(window.localStorage.getItem('country')) === null)
    {
      getLocation()
    }
  
  }, [])


  
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="home/" element={<LandingPage/>} />
      <Route path="consultation/" element={<Consultation/>} />

    </Routes>
  );
}

export default App;
